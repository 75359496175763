export default {
    path: '/',
    name: 'console',
    component: () =>
        import ('@/layouts/ConsoleLayout'),
    redirect: '/overview/shop',
    children: [
        // 概况
        {
            path: 'overview/shop',
            name: 'shopOverview',
            meta: { title: '商城概览' },
            component: () =>
                import ('@/views/shopOverview'),
        },
        {
            path: 'overview/goods',
            name: 'goodsOverview',
            meta: { title: '商品统计' },
            component: () =>
                import ('@/views/goodsOverview'),
        },
        {
            path: 'overview/transaction',
            name: 'transactionOverview',
            meta: { title: '交易统计' },
            component: () =>
                import ('@/views/transactionOverview'),
        },
        // 店铺装修
        {
            path: 'decoration/page',
            name: 'pageDecoration',
            meta: { title: '页面装修' },
            component: () =>
                import ('@/views/pageDecoration'),
        },
        // 商品管理
        {
            path: 'goods/list',
            name: 'goodsList',
            meta: { title: '全部商品' },
            component: () =>
                import ('@/views/goodsList'),
        },

        {
            path: 'goods/sold_out',
            name: 'goodsSoldOut',
            meta: { title: '已售罄商品' },
            component: () =>
                import ('@/views/goodsSoldOut'),
        },
        {
            path: 'goods/create',
            name: 'goodsCreate',
            meta: { title: '添加商品' },
            component: () =>
                import ('@/views/goodsCreate'),
        },
        // {
        //     path: 'goods/freight_template',
        //     name: 'freightTemplate',
        //     meta: { title: '运费模板' },
        //     component: () =>
        //         import ('@/views/freightTemplate'),
        // },
        {
            path: 'goods/category',
            name: 'goodsCategory',
            meta: { title: '商品分类' },
            component: () =>
                import ('@/views/goodsCategory'),
        },
        {
            path: 'goods/units',
            name: 'goodsUnits',
            meta: { title: '单位管理' },
            component: () =>
                import ('@/views/goodsUnits'),
        },
        {
            path: 'goods/evaluate',
            name: 'goodsEvaluate',
            meta: { title: '评价管理' },
            component: () =>
                import ('@/views/goodsEvaluate'),
        },
        {
            path: 'goods/import',
            name: 'goodsImport',
            meta: { title: '商品导入' },
            component: () =>
                import ('@/views/goodsImport'),
        },
        // 订单管理
        {
            path: 'order/list',
            name: 'orderList',
            meta: { title: '全部订单' },
            component: () =>
                import ('@/views/orderList'),
        },
        {
            path: 'order/detail',
            name: 'orderDetail',
            meta: { title: '商品详情' },
            component: () =>
                import ('@/views/orderList/detail'),
        },

        {
            path: 'order/pending',
            name: 'orderPending',
            meta: { title: '待发货订单' },
            component: () =>
                import ('@/views/orderPending'),
        },
        {
            path: 'order/return',
            name: 'orderReturn',
            meta: { title: '退货退款订单' },
            component: () =>
                import ('@/views/orderReturn'),
        },
        {
            path: 'order/returnDetail',
            name: 'returnDetail',
            meta: { title: '商品详情' },
            component: () =>
                import ('@/views/orderReturn/returnDetail'),
        },

        // {
        //     path: 'order/pick_up',
        //     name: 'orderPickUp',
        //     meta: { title: '自提订单' },
        //     component: () =>
        //         import ('@/views/orderPickUp'),
        // },
        // 会员管理
        {
            path: 'member/list',
            name: 'memberList',
            meta: { title: '会员列表' },
            component: () =>
                import ('@/views/memberList'),
        },
        {
            path: 'member/detail',
            name: 'memberDetail',
            meta: { title: '会员详情' },
            component: () =>
                import ('@/views/memberList/memberDetail'),
        },
        {
            path: 'member/balanceDetail',
            name: 'memberBalanceDetail',
            meta: { title: '余额明细' },
            component: () =>
                import ('@/views/memberList/balanceDetail'),
        },
        {
            path: 'member/pointsDetail',
            name: 'memberPointsDetail',
            meta: { title: '积分明细' },
            component: () =>
                import ('@/views/memberList/pointsDetail'),
        },
        {
            path: 'member/import',
            name: 'memberImport',
            meta: { title: '会员导入' },
            component: () =>
                import ('@/views/memberImport'),
        },
        {
            path: 'member/card',
            name: 'memberCard',
            meta: { title: '会员卡' },
            component: () =>
                import ('@/views/memberCard'),
            redirect: 'member/card/show',
            children: [{
                    path: 'show',
                    name: 'memberCardShow',
                    meta: { title: '会员卡' },
                    component: () =>
                        import ('@/views/memberCard/card'),
                },
                {
                    path: 'add',
                    name: 'memberCardAdd',
                    meta: { title: '等级会员卡' },
                    component: () =>
                        import ('@/views/memberCard/add'),
                }
            ]
        },
        {
            path: '/member/activateCard',
            name: 'activateCard',
            meta: { title: '会员激活' },
            component: () =>
                import ('@/views/memberCard/activateCard'),
        },
        {
            path: 'member/label',
            name: 'memberLabel',
            meta: { title: '标签管理' },
            component: () =>
                import ('@/views/memberLabel'),
        },
        {
            path: 'member/points',
            name: 'memberPoints',
            meta: { title: '积分管理' },
            component: () =>
                import ('@/views/memberPoints'),
        },
        // 基础配置
        {
            path: 'config/shop',
            name: 'shopConfig',
            meta: { title: '店铺配置' },
            component: () =>
                import ('@/views/shopConfig'),
        },
        {
            path: 'config/freight_template',
            name: 'freightTemplate',
            meta: { title: '运费模板' },
            component: () =>
                import ('@/views/freightTemplate'),
        },
        // {
        //   path: 'config/transaction',
        //   name: 'transactionConfig',
        //   meta: { title: '交易配置' },
        //   component: () => import('@/views/transactionConfig'),
        // },
        {
            path: 'config/permissions',
            name: 'permissionsConfig',
            meta: { title: '店铺权限' },
            component: () =>
                import ('@/views/permissionsConfig'),
        },
    ],
}