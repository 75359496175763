/*
 * @Author: frank
 * @Description:
 * @Date: 2021-01-14 08:50:21
 * @LastEditTime: 2021-05-05 15:28:51
 * @FilePath: /shop_frontend/src/http/axios.js
 */
import axios from "axios";
import Cookies from 'js-cookie'
import Router from '@/router/index'
let service = axios.create({
    baseURL: process.env.NODE_ENV === "development" ? "/api/" : "/api/",
    timeout: 300000,
    headers: { "Content-Type": "application/json", 'X-CSRFToken': Cookies.get('csrftoken') },
    withCredentials: true,
});

// 请求队列
let queue = [];
let CancelToken = axios.CancelToken;
let distroy = (config, flag = "req") => {
    for (let i in queue) {
        if (
            `${config.url}&${config.method}` === queue[i].key ||
            `${config.url}&${config.method}` === `${config.baseURL}${queue[i].key}`
        ) {
            if (flag === "req") {
                queue[i].cancel("取消请求");
            }
            queue.splice(i, 1);
        }
    }
};

// 请求拦截
service.interceptors.request.use(
    (config) => {

        config.headers['X-CSRFToken'] = Cookies.get('csrftoken')

        if (config.responseType === "blob") {
            config.headers['Content-Type'] = 'application/ms-excel'
        }

        // console.log(config, 'config')
        // console.log(config, '请求')
        // if (!queue.length) {}
        // distroy(config);

        // config.cancelToken = new CancelToken(function executor(c) {
        //   queue.push({
        //     key: `${config.url}&${config.method}`,
        //     cancel: c,
        //   });
        // });
        return config;
    },
    (err) => {
        return Promise.reject(err);
    }
);

// 返回拦截
service.interceptors.response.use(
    (response) => {
        // distroy(response.config, "res");
        // if (!queue.length) {
        // }
        if (response.status == 200 || response.status == 201) {
            return response.data;
        } else {
            if (response.status == 401) {
                Router.push({
                    path: '/user/login'
                })
                return response
            }


            return response
        }

    },
    (err) => {
        if (err.response.status == 401) {
            Router.push({
                path: '/user/login'
            })
        }
        antd.message.error(err.response.data.detail)
        return Promise.reject(err);
    }
);

export default service;