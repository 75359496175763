export default {
  path: '/',
  component: () => import('@/layouts/ExceptionLayout'),
  children: [
    {
      path: '*',
      name: 'notFound',
      meta: { title: '页面未找到' },
      component: () => import('@/views/notFound'),
    },
  ],
}