/*
 * @Date: 2021-01-25 14:09:55
 * @LastEditors: frank
 * @LastEditTime: 2021-05-05 16:00:17
 * @FilePath: /shop_frontend/src/store/index.js
 * @Description: Description
 */
import Vuex from 'vuex'
import Vue from 'vue'
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)
import axios from '../http/axios'
export default new Vuex.Store({
    state: {
        userInfo: null,
        isReload: false
    },
    getters: {},
    mutations: {
        setUserInfo(state, payload) {
            state.userInfo = payload
        },
        setReload(state, payload) {
            state.isReload = payload
        }
    },
    actions: {
        getUserInfo({ commit }) {
            return axios.get('/users/get_info/').then(res => {
                console.log(res)
                commit('setUserInfo', res)
            })
        },
        logout({ commit }) {
            return axios.post('/users/logout/').then(res => {
                commit('setUserInfo', null)
                sessionStorage.clear();
            })
        }
    },
    modules: {},
    plugins: [createPersistedState({
        storage: window.sessionStorage
    })],
})