import 'core-js/stable';
import 'regenerator-runtime/runtime';
import VueRouter from 'vue-router'
import router from './router'
import Vue from 'vue'
import App from './App.vue'
import Antd from "ant-design-vue/es";
import 'ant-design-vue/dist/antd.css';


import '@/assets/css/base.less'





// import "swiper/swiper.less";

// Import Swiper and modules
// import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper/core';

// Install modules
// SwiperCore.use([Navigation, Pagination, Scrollbar]);


import axios from './http/axios'

import store from './store/index'

Vue.prototype.$axios = axios;

Vue.config.productionTip = false

console.log(process.env)

Vue.config.devtools = process.env.NODE_ENV === 'development';

import mixins from './utils/mixins'

import vcolorpicker from 'vcolorpicker'

Vue.use(vcolorpicker);

Vue.mixin(mixins)


Vue.use(VueRouter);

// const IconFont = antd.Icon.createFromIconfontCN({
//     scriptUrl: "//at.alicdn.com/t/font_2513289_zlssiuortjo.js",
// });


// Vue.component('IconFont', IconFont)


router.beforeEach((to, from, next) => {
    console.log(to, from, '---', store)
    if (store.state.userInfo && store.state.userInfo.username) {
        next()
    } else {
        if (to.name === 'login') {
            next()
        } else {
            next('/user/login')
        }

    }


})
Vue.use(Antd);


// Vue.use(antd)

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')