/*
 * @Date: 2021-03-15 22:01:47
 * @LastEditors: frank
 * @LastEditTime: 2021-03-18 14:00:12
 * @FilePath: /shop_frontend/src/utils/mixins.js
 * @Description: Description
 */
export default {
    filters: {
        statusShow (status) {
            if (typeof status !== 'number') {
                return ''
            }
            status = status.toString();
            return {
                '0': '待付款',
                '1': '待发货',
                '2': '已发货',
                '3': '已完成',
                '4': '售后中',
                '5': '售后完成',
                '6': '已关闭',
                '7': '已收货',
            }[status]
        },
        returnStatusShow (status) {
            if (typeof status !== 'number') {
                return ''
            }
            status = status.toString();
            return {
                '0': '退款申请等待商家确认',
                '1': '商家拒绝退款申请',
                '2': '商家同意退款, 等待买家退货',
                '3': '商家未收货, 拒绝退款',
                '4': '退款撤销',
                '5': '退款成功',
            }[status]

        }
    }
}